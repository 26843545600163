import {
  defineNuxtRouteMiddleware,
  navigateTo,
  useSupabaseClient,
} from '#imports';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const supabase = useSupabaseClient();
  const {
    data: { user },
    error: userError,
  } = await supabase.auth.getUser();

  const authPath = from.path ? `auth?redirect=${from.path}` : '/auth';

  if (userError) {
    return navigateTo(authPath);
  }

  if (!user) {
    return navigateTo(authPath);
  }
});
